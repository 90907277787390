import React, { useState, useEffect, useRef } from 'react';
import Styled from './index.styled';
import CustomImage from '../../atoms/CustomImage';

const Collage = ({ collage, isHero }) => {
	const [isVisible, setIsVisible] = useState(false);
    const collageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once triggered
                }
            },
            { threshold: 0.2 } // Requires 60% of the component to be visible before animating
        );

        if (collageRef.current) observer.observe(collageRef.current);
        return () => observer.disconnect();
    }, []);

    return (
	<Styled.Collage ref={collageRef} isVisible={isVisible}>
		<Styled.Collage__imgContainerSmall isHero={isHero}>
			<CustomImage {...collage[0]} width="200" height="143" />
		</Styled.Collage__imgContainerSmall>

		<Styled.Collage__imgContainerMedium isHero={isHero}>
			<CustomImage {...collage[1]} width="300" height="180" />
		</Styled.Collage__imgContainerMedium>

		<Styled.Collage__imgContainerLarge isHero={isHero}>
			<CustomImage {...collage[2]} width="300" height="250" />
		</Styled.Collage__imgContainerLarge>

		<Styled.Collage__imgContainerRegular isHero={isHero}>
			<CustomImage {...collage[3]} width="300" height="250" />
		</Styled.Collage__imgContainerRegular>
	</Styled.Collage>
);
};

export default Collage;
